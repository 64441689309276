import { useState, useEffect } from 'react';

const useCurrentURL = () => {
  const [currentURL, setCurrentURL] = useState('');

  useEffect(() => {
    setCurrentURL(window.location.href);
  }, []);

  return currentURL;
};

export default useCurrentURL;
