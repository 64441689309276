import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const PageTitle = styled.h1`
  color: #3498db;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: #2c3e50;
  font-size: 1.8rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;

  &:before {
    content: '•';
    color: #3498db;
    position: absolute;
    left: 0;
  }
`;

const OrderedList = styled.ol`
  padding-left: 1.5rem;
`;

const OrderedListItem = styled.li`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 0.5rem;
`;

const StyledLink = styled(Link)`
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: #e74c3c;
  }
`;

const HowToGet = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>How to Get a DUNS Number | Step-by-Step Guide</title>
        <meta name="description" content="Learn how to obtain a DUNS Number for your business with our comprehensive guide. Discover the process, requirements, and tips for a smooth application." />
        <meta name="og:title" content="How to Get a DUNS Number | Step-by-Step Guide" />
        <meta name="twitter:title" content="How to Get a DUNS Number | Step-by-Step Guide" />
        <meta name="og:description" content="Learn how to obtain a DUNS Number for your business with our comprehensive guide. Discover the process, requirements, and tips for a smooth application." />
        <meta name="twitter:description" content="Learn how to obtain a DUNS Number for your business with our comprehensive guide. Discover the process, requirements, and tips for a smooth application." />
      </Helmet>
      <PageTitle>How to Get a DUNS Number</PageTitle>
      <Paragraph>Obtaining a DUNS (Data Universal Numbering System) Number is a straightforward process. Follow these steps to get your unique business identifier:</Paragraph>
      
      <SectionTitle>Step 1: Check if Your Business Already Has a DUNS Number</SectionTitle>
      <Paragraph>Before applying, use our <StyledLink to="/duns-lookup">DUNS Number lookup service</StyledLink> to check if your business already has a DUNS Number assigned. Many businesses are surprised to find they already have one.</Paragraph>
      
      <SectionTitle>Step 2: Gather Required Information</SectionTitle>
      <Paragraph>To apply for a DUNS Number, you'll need the following information:</Paragraph>
      <List>
        <ListItem>Legal name of your business</ListItem>
        <ListItem>Business headquarters name and address</ListItem>
        <ListItem>Doing Business As (DBA) or other names by which your business is commonly known</ListItem>
        <ListItem>Physical address, city, state, and ZIP code</ListItem>
        <ListItem>Mailing address (if different from physical address)</ListItem>
        <ListItem>Telephone number</ListItem>
        <ListItem>Contact name and title</ListItem>
        <ListItem>Number of employees at your physical location</ListItem>
        <ListItem>Whether you are a home-based business</ListItem>
      </List>
      
      <SectionTitle>Step 3: Choose Your Application Method</SectionTitle>
      <Paragraph>There are several ways to apply for a DUNS Number:</Paragraph>
      <OrderedList>
        <OrderedListItem><strong>Online:</strong> Visit the Dun & Bradstreet website and fill out the online application form.</OrderedListItem>
        <OrderedListItem><strong>Phone:</strong> Call the D&B customer service line to apply over the phone.</OrderedListItem>
        <OrderedListItem><strong>Mail:</strong> Send a written request to D&B with all required information.</OrderedListItem>
      </OrderedList>
      
      <SectionTitle>Step 4: Submit Your Application</SectionTitle>
      <Paragraph>Complete the application process through your chosen method. Be sure to provide accurate and up-to-date information to avoid delays.</Paragraph>
      
      <SectionTitle>Step 5: Wait for Processing</SectionTitle>
      <Paragraph>The standard processing time for a DUNS Number application is 30 business days. However, if you need your DUNS Number sooner, expedited processing is available for a fee.</Paragraph>
      
      <SectionTitle>Step 6: Receive Your DUNS Number</SectionTitle>
      <Paragraph>Once your application is processed, you'll receive your nine-digit DUNS Number. Keep this number in a safe place, as you'll need it for various business transactions.</Paragraph>
      
      <SectionTitle>Step 7: Verify and Update Your Information</SectionTitle>
      <Paragraph>After receiving your DUNS Number, it's crucial to verify that all the information associated with it is correct. You can do this by:</Paragraph>
      <OrderedList>
        <OrderedListItem>Logging into the Dun & Bradstreet portal</OrderedListItem>
        <OrderedListItem>Reviewing your business profile</OrderedListItem>
        <OrderedListItem>Making any necessary updates or corrections</OrderedListItem>
      </OrderedList>
      <Paragraph>Keeping your information current ensures that potential partners or creditors have accurate data about your business.</Paragraph>
      
      <SectionTitle>Step 8: Start Using Your DUNS Number</SectionTitle>
      <Paragraph>Once you have your DUNS Number and have verified your information, you can start using it for various purposes:</Paragraph>
      <List>
        <ListItem>Applying for government contracts</ListItem>
        <ListItem>Establishing business credit</ListItem>
        <ListItem>Registering with global marketplaces</ListItem>
        <ListItem>Verifying your business to potential partners or customers</ListItem>
      </List>
      
      <SectionTitle>Tips for a Smooth Application Process</SectionTitle>
      <List>
        <ListItem>Double-check all information before submitting your application</ListItem>
        <ListItem>Be prepared to verify your business information if requested</ListItem>
        <ListItem>If you're an international business, check D&B's website for country-specific instructions</ListItem>
        <ListItem>Remember that obtaining a DUNS Number is free for all businesses</ListItem>
        <ListItem>Consider setting up alerts to monitor any changes to your business credit file</ListItem>
        <ListItem>Keep your DUNS Number confidential to prevent potential misuse</ListItem>
      </List>
      
      <Paragraph>By following these steps, you'll be on your way to obtaining your DUNS Number and unlocking new opportunities for your business. Remember, a DUNS Number is more than just an identifier – it's a key to establishing your business's credibility and opening doors to growth opportunities worldwide.</Paragraph>
    </PageContainer>
  );
};

export default HowToGet;