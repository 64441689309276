import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import Turnstile from 'react-turnstile';
import countryData from '../data/countryData';
import { Search, Globe, Eye, X } from 'lucide-react';
import { Helmet } from 'react-helmet';
import AdComponent from './AdComponent';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const LookupContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-out;
`;

const Title = styled.h2`
  color: #2c3e50;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  animation: ${slideIn} 0.5s ease-out;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const InputGroup = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem 1rem 0.8rem 3rem;
  font-size: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:focus {
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
    outline: none;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.8rem 1rem 0.8rem 3rem;
  font-size: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 1rem top 50%;
  background-size: 0.65rem auto;
  transition: all 0.3s ease;

  &:focus {
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
    outline: none;
  }
`;

const Icon = styled.span`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #7f8c8d;
`;

const ErrorMessage = styled.p`
  color: #e74c3c;
  font-weight: bold;
  text-align: center;
  animation: ${fadeIn} 0.3s ease-out;
`;

const TurnstileContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

const ResultContainer = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${slideIn} 0.5s ease-out;
`;

const ResultItem = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #e1e4e8;
  transition: background-color 0.3s ease;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: #eef2f7;
  }
`;

const ResultTitle = styled.h3`
  color: #2c3e50;
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
`;

const ResultDetail = styled.p`
  margin: 0.5rem 0;
  color: #34495e;
  font-size: 1rem;
  display: flex;
  align-items: center;
`;

const Highlight = styled.span`
  font-weight: bold;
  color: #3498db;
  margin-right: 0.5rem;
`;

const Button = styled.button`
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const LoadingSpinner = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ViewButton = styled(Button)`
  background-color: #2ecc71;
  &:hover {
    background-color: #27ae60;
  }
`;

const AdOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const AdContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  // width: 100%;
  // height: 100%;
  // min-width: 100%;
  // min-height: 100%;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  z-index: 1001;
  
  &:hover {
    color: #e74c3c;
  }
`;

function TurnstileWidget({ onVerify }) {
  return (
    <Turnstile
      sitekey="0x4AAAAAAAjGQBS_90l5k1x-"
      onVerify={(token) => onVerify(token)}
      appearance='always'
    />
  );
}

const DUNSLookup = ({ preselectedCountry }) => {
  const [companyName, setCompanyName] = useState('');
  const [country, setCountry] = useState('');
  const [city] = useState('');
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showingAd, setShowingAd] = useState(null);
  const [viewedDUNS, setViewedDUNS] = useState({});

  useEffect(() => {
    if (preselectedCountry) {
      setCountry(preselectedCountry.toUpperCase());
    }
  }, [preselectedCountry]);

  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResults([]);
    setError(null);
    setIsSubmitting(true);

    if (!captchaToken) {
      setError('Please complete the captcha before submitting.');
      setIsSubmitting(false);
      return;
    }
    
    const apiUrl = 'https://duns-lookup.kevin-8c6.workers.dev';

    try {
      const captchaVerification = await axios.post('https://duns-turnstyle.kevin-8c6.workers.dev/', {
        'cf-turnstile-response': captchaToken
      });

      if (!captchaVerification.data.success) {
        setIsSubmitting(false);
        setError(`Captcha verification failed.`);
        return;
      }

      const response = await axios.get(apiUrl, {
        params: {
          name: companyName,
          country: country,
          city: city
        },
        headers: {
          'Origin': window.location.origin,
        }
      });

      if (response.data && response.data.searchCandidates) {
        const parsedResults = response.data.searchCandidates.map(candidate => ({
          companyName: candidate.organization.primaryName,
          duns: candidate.organization.duns,
          registrationNumber: candidate.organization.registrationNumbers?.[0]?.registrationNumber || 'N/A'
        }));
        setResults(parsedResults);
      } else {
        setError('No results found for the given company.');
      }
    } catch (err) {
      setError('An error occurred while looking up the DUNS number. Please try again.');
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleViewDUNS = (index) => {
    setShowingAd(index);
  };

  const handleCloseAd = () => {
    setViewedDUNS(prev => ({ ...prev, [showingAd]: true }));
    setShowingAd(null);
  };

  return (
    <LookupContainer>
      <Helmet>
        <title>Lookup Your DUNS Number</title>
        <meta name="description" content="Quickly find your DUNS number" />
        <meta name="og:title" content="Lookup Your DUNS Number" />
        <meta name="twitter:title" content="Lookup Your DUNS Number" />
        <meta name="og:description" content="Quickly find your DUNS number" />
        <meta name="twitter:description" content="Quickly find your DUNS number" />
      </Helmet>
      <Title>DUNS Number Lookup</Title>
      <Form onSubmit={handleSubmit}>
        <InputGroup>
          <Icon><Search size={18} /></Icon>
          <Input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Company Name"
            required
          />
        </InputGroup>
        <InputGroup>
          <Icon><Globe size={18} /></Icon>
          <Select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
          >
            <option value=''>Select a country</option>
            {Object.keys(countryData).map((countryCode) => (
              <option key={countryCode} value={countryCode}>
                {countryData[countryCode].name} ({countryCode})
              </option>
            ))}
          </Select>
        </InputGroup>
        {/* <InputGroup>
          <Icon><MapPin size={18} /></Icon>
          <Input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="City"
          />
        </InputGroup> */}
        <TurnstileContainer>
          <TurnstileWidget onVerify={handleCaptchaVerify} />
        </TurnstileContainer>
        <Button id={`duns-submit`} type="submit" disabled={isSubmitting}>
          {isSubmitting ? <LoadingSpinner /> : 'Lookup DUNS Number'}
        </Button>
      </Form>

      {results.length > 0 && (
      <ResultContainer>
        <ResultTitle>Results:</ResultTitle>
        {results.map((result, index) => (
          <ResultItem key={index}>
            <ResultTitle>Company Details</ResultTitle>
            <ResultDetail><Highlight>Company Name:</Highlight> {result.companyName}</ResultDetail>
            <ResultDetail>
              <Highlight>DUNS Number:</Highlight> 
              {viewedDUNS[index] ? result.duns : (
                <ViewButton onClick={() => handleViewDUNS(index)}>
                  <Eye size={18} />
                  View DUNS
                </ViewButton>
              )}
            </ResultDetail>
            <ResultDetail><Highlight>Registration Number:</Highlight> {result.registrationNumber}</ResultDetail>
          </ResultItem>
        ))}
      </ResultContainer>
      )}

      {showingAd !== null && (
        <AdOverlay>
          <AdContent>
            <CloseButton onClick={handleCloseAd}>
              <X size={24} />
            </CloseButton>
            <div>
              <div style={{textTransform: "uppercase"}}>Advertisement</div>

              <div style={{width:"320px",height:"270px"}}>
                <AdComponent adId="6904193077" position="interstitial"/>
                {/* <a href="https://nordvpn.sjv.io/c/5671880/512103/7452" target="_blank" id="512103" rel="noreferrer">
                  <img src="//a.impactradius-go.com/display-ad/7452-512103" border="0" alt="" width="300" height="250"/>
                </a>
                <img height="0" width="0" src="https://nordvpn.sjv.io/i/5671880/512103/7452" style={{position:"absolute",visibility:"hidden"}} border="0" />               */}
                {/* <AdSlot position="top" adId="6904193077" format="auto" /> */}
              </div>
            </div>
          </AdContent>
        </AdOverlay>
      )}

      {error && <ErrorMessage>{error}{error.match(/captcha/i) ? <span className="span-link" onClick={() => window.location.reload(true)}> Please try reload the page.</span> : <span> Please try again.</span>}</ErrorMessage>}
    </LookupContainer>
  );
};

export default DUNSLookup;