import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const PageTitle = styled.h1`
  color: #3498db;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: #2c3e50;
  font-size: 1.8rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;

  &:before {
    content: '•';
    color: #3498db;
    position: absolute;
    left: 0;
  }
`;

const About = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>About Our DUNS Number Lookup Service | Global Business Identifier</title>
        <meta name="description" content="Learn about our comprehensive DUNS number lookup service. We provide fast, accurate information to help businesses establish credibility and expand globally." />
      </Helmet>
      <PageTitle>About Our DUNS Number Lookup Service</PageTitle>
      <Paragraph>Welcome to the leading online platform for DUNS number lookups and information. Our mission is to empower businesses worldwide by providing quick, reliable access to DUNS numbers and related business information.</Paragraph>
      
      <SectionTitle>Our Expertise</SectionTitle>
      <Paragraph>With years of experience in business information services, we understand the critical role that DUNS numbers play in today's global marketplace. Our team of experts is dedicated to delivering accurate, up-to-date information to help businesses establish credibility, secure contracts, and expand their operations internationally.</Paragraph>
      
      <SectionTitle>Why Choose Us?</SectionTitle>
      <List>
        <ListItem>Comprehensive Database: Access to millions of business records worldwide</ListItem>
        <ListItem>User-Friendly Interface: Easy-to-use search tools for quick results</ListItem>
        <ListItem>Accurate and Current: Regular updates ensure the most recent information</ListItem>
        <ListItem>Global Coverage: DUNS number lookup services for businesses in over 200 countries</ListItem>
        <ListItem>Expert Support: Dedicated customer service to assist with your inquiries</ListItem>
      </List>
      
      <SectionTitle>Our Commitment</SectionTitle>
      <Paragraph>We are committed to supporting businesses of all sizes in their growth and development. By providing essential DUNS number services, we aim to facilitate global trade, foster business relationships, and contribute to economic growth worldwide.</Paragraph>
      <Paragraph>Whether you're a small startup looking to establish credibility or a large corporation seeking to verify potential partners, our DUNS number lookup service is here to meet your needs.</Paragraph>
    </PageContainer>
  );
};

export default About;