import React from 'react';
// import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer>
      <div className="container">
        <div className="footer-content">
          <p>&copy; {currentYear} Global DUNS Number Finder. All rights reserved.</p>
          {/* <nav>
            <ul>
              <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/terms">Terms of Service</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </nav> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;