import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import countryData from '../data/countryData';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const PageTitle = styled.h1`
  color: #3498db;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: #2c3e50;
  font-size: 1.8rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;

  &:before {
    content: '•';
    color: #3498db;
    position: absolute;
    left: 0;
  }
`;

const StyledLink = styled(Link)`
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: #e74c3c;
  }
`;

const ErrorContainer = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: #f8d7da;
  border-radius: 8px;
  margin-top: 2rem;
`;

const FlagTitle = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1rem;
`;

// const FlagEmoji = styled.span`
//   font-size: 3rem;
// `;

const FlagImage = styled.img`
  width: 48px;
  height: 36px;
  object-fit: cover;
  border: 1px solid #e0e0e0;
`;

// const getCountryFlagEmoji = (countryCode) => {
//   const codePoints = countryCode
//     .toUpperCase()
//     .split('')
//     .map(char => 127397 + char.charCodeAt());
//   return String.fromCodePoint(...codePoints);
// };

const MetaData = ({ title, description }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="og:title" content={title} />
    <meta name="twitter::title" content={title} />
    <meta name="description" content={description} />
    <meta name="og:description" content={description} />
    <meta name="twitter::description" content={description} />
  </Helmet>
);

const Section = ({ title, children }) => (
  <div>
    <SectionTitle>{title}</SectionTitle>
    {children}
  </div>
);

const CountryPage = () => {
  const { country } = useParams();
  const data = countryData[country];

  if (!data) {
    return (
      <ErrorContainer>
        <MetaData
          title="Country Not Found | Global Business Identifier"
          description="The country you are looking for does not exist in our DUNS Number database."
        />
        <PageTitle>Country Not Found</PageTitle>
        <Paragraph>Sorry, we don't have information for the country you are looking for.</Paragraph>
      </ErrorContainer>
    );
  }

  const { name, content, specificInfo, benefits, usage, lookupLink, titles } = data;

  const pageTitle = `DUNS Number in ${name} | Global Business Identifier`;
  const pageDescription = `Learn about DUNS Numbers in ${name}. Discover how to obtain and use your DUNS Number for business growth and credibility in ${name}.`;

  // const flagEmoji = getCountryFlagEmoji(country);
  const flagSrc = `https://flagcdn.com/w80/${country.toLowerCase()}.png`;

  return (
    <PageContainer>
      <MetaData title={pageTitle} description={pageDescription} />

      <FlagTitle>
        {/* <FlagEmoji>{flagEmoji}</FlagEmoji> */}
        <FlagImage src={flagSrc} alt={`Flag of ${name}`} />
        <PageTitle>{`DUNS Number in ${name}`}</PageTitle>
      </FlagTitle>

      {content && <Paragraph>{content}</Paragraph>}

      {specificInfo && (
        <Section title={titles.getting}>
          <Paragraph>{specificInfo}</Paragraph>
        </Section>
      )}

      {benefits && (
        <Section title={titles.benefits}>
          <List>
            {benefits.map((benefit, index) => (
              <ListItem key={index}>{benefit}</ListItem>
            ))}
          </List>
        </Section>
      )}

      {usage && (
        <Section title={titles.usage}>
          <List>
            {usage.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </List>
        </Section>
      )}

      {lookupLink && (
        <Paragraph>
          {lookupLink}{' '}
          <StyledLink to="/duns-lookup">DUNS Lookup</StyledLink>
        </Paragraph>
      )}
    </PageContainer>
  );
};

export default CountryPage;