import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const PageTitle = styled.h1`
  color: #3498db;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: #2c3e50;
  font-size: 1.8rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;

  &:before {
    content: '•';
    color: #3498db;
    position: absolute;
    left: 0;
  }
`;

const OrderedList = styled.ol`
  padding-left: 1.5rem;
`;

const OrderedListItem = styled.li`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 0.5rem;
`;

const StyledLink = styled(Link)`
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: #e74c3c;
  }
`;

const WhatIsDUNS = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>What is a DUNS Number? | Comprehensive Guide</title>
        <meta name="description" content="Discover what a DUNS Number is, its importance in global business, and how it can benefit your company. Learn about the Data Universal Numbering System." />
        <meta name="og:title" content="What is a DUNS Number? | Comprehensive Guide" />
        <meta name="twitter:title" content="What is a DUNS Number? | Comprehensive Guide" />
        <meta name="og:description" content="Discover what a DUNS Number is, its importance in global business, and how it can benefit your company. Learn about the Data Universal Numbering System." />
        <meta name="twitter:description" content="Discover what a DUNS Number is, its importance in global business, and how it can benefit your company. Learn about the Data Universal Numbering System." />
      </Helmet>
      <PageTitle>What is a DUNS Number?</PageTitle>
      <Paragraph>A DUNS (Data Universal Numbering System) Number is a unique nine-digit identifier for businesses, issued by Dun & Bradstreet (D&B). It's widely recognized as the universal standard for identifying and tracking businesses globally.</Paragraph>
      
      <SectionTitle>Key Features of DUNS Numbers</SectionTitle>
      <List>
        <ListItem>Unique: No two organizations have the same DUNS Number</ListItem>
        <ListItem>Global: Used in over 200 countries and territories</ListItem>
        <ListItem>Persistent: The number stays with a business location even if ownership changes</ListItem>
        <ListItem>Widely Recognized: Accepted by governments, trade organizations, and corporations worldwide</ListItem>
      </List>
      
      <SectionTitle>History and Importance</SectionTitle>
      <Paragraph>Introduced in 1963, the DUNS Number has become a crucial element in global commerce. It's used for various purposes, including:</Paragraph>
      <List>
        <ListItem>Verifying business identity</ListItem>
        <ListItem>Checking creditworthiness</ListItem>
        <ListItem>Tracking corporate family trees</ListItem>
        <ListItem>Facilitating global trade</ListItem>
        <ListItem>Enabling government contract applications</ListItem>
      </List>
      
      <SectionTitle>DUNS Number Structure</SectionTitle>
      <Paragraph>The nine-digit DUNS Number has no inherent meaning. It's simply a unique identifier assigned sequentially to each business entity in D&B's database.</Paragraph>
      
      <SectionTitle>How DUNS Numbers are Used</SectionTitle>
      <Paragraph>DUNS Numbers play a vital role in various business processes:</Paragraph>
      <List>
        <ListItem>Government Contracting: Required for bidding on U.S. government contracts</ListItem>
        <ListItem>Global Expansion: Facilitates international business relationships</ListItem>
        <ListItem>Credit Checks: Used by potential partners or lenders to assess business credit</ListItem>
        <ListItem>Supply Chain Management: Helps in tracking and managing suppliers</ListItem>
        <ListItem>Marketing and Sales: Enables targeted B2B marketing efforts</ListItem>
      </List>
      <Paragraph>Understanding and utilizing your DUNS Number can open doors to new opportunities and help establish your business's credibility in the global marketplace.</Paragraph>
      
      <SectionTitle>How to Obtain a DUNS Number</SectionTitle>
      <Paragraph>Obtaining a DUNS Number is free and can be done through the official Dun & Bradstreet website. The process typically involves:</Paragraph>
      <OrderedList>
        <OrderedListItem>Gathering necessary business information</OrderedListItem>
        <OrderedListItem>Submitting an application online or via phone</OrderedListItem>
        <OrderedListItem>Verifying your business details</OrderedListItem>
        <OrderedListItem>Receiving your DUNS Number (usually within 30 business days)</OrderedListItem>
      </OrderedList>
      <Paragraph>For more detailed information on obtaining a DUNS Number, visit our <StyledLink to="/how-to-get">How to Get a DUNS Number</StyledLink> page.</Paragraph>
    </PageContainer>
  );
};

export default WhatIsDUNS;