import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './AdSlot.module.css';

const AdUnit = ({ position, adId }) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.error('AdSense error:', err);
    }
  }, []);

  return (
    <ins 
      className={`adsbygoogle ${styles.adSense} ${styles[position]}`}
      data-ad-client="ca-pub-6954832650926400"
      data-ad-slot={adId}
    />
  );
};

const AdComponent = ({ position, adId }) => {
  const location = useLocation();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [location.pathname]);

  return (
    <div className={`${styles.adSlot}`} style={{width:"100%", textAlign: "center"}}>
      <AdUnit key={`ad-${position}-${key}`} position={position} adId={adId} />
    </div>
  );
};

export default AdComponent;