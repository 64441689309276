const countryData = {
    us: {
      name: 'United States',
      content: 'In the United States, DUNS Numbers are widely used for government contracts and are required for businesses seeking federal contracts or grants.',
      specificInfo: 'The U.S. Small Business Administration (SBA) provides a free DUNS Number service for all US-based businesses.',
      benefits: [
        'Enhance your business credibility in the United States market',
        'Access to government contracts and tenders',
        'Easier verification process for potential partners and clients',
        'Improved chances of securing business loans and credit',
        'Required for Google Play Android and iOS app developers',
      ],
      usage: [
        'Register for government contracts',
        'Establish business credit',
        'Verify your business identity to potential partners',
        'Apply for loans or lines of credit',
        'Register apps on Google Play and the Apple App Store',
      ],
      lookupLink: 'For more information or to look up your DUNS Number, visit our DUNS Lookup page.',
      titles: {
        benefits: 'Benefits of Having a DUNS Number in the United States',
        usage: 'How to Use Your DUNS Number in the United States',
        getting: 'Getting a DUNS Number in the United States',
      },
    },
    fr: {
      name: 'France',
      content: 'En France, les numéros DUNS sont largement utilisés pour les contrats gouvernementaux et sont essentiels pour les entreprises qui cherchent à développer leurs activités à l\'international.',
      specificInfo: 'Les entreprises françaises peuvent obtenir un numéro DUNS auprès de Dun & Bradstreet France.',
      benefits: [
        'Améliorez la crédibilité de votre entreprise sur le marché français',
        'Accédez aux contrats et appels d\'offres gouvernementaux',
        'Simplifiez la vérification par des partenaires potentiels',
        'Améliorez vos chances d\'obtenir des prêts commerciaux',
        'Obligatoire pour les développeurs d\'applications Google Play et iOS',
      ],
      usage: [
        'S\'inscrire aux contrats gouvernementaux',
        'Établir un crédit commercial',
        'Vérifier l\'identité de votre entreprise auprès de partenaires',
        'Demander des prêts ou des lignes de crédit',
        'Enregistrer des applications sur Google Play et l\'App Store d\'Apple',
      ],
      lookupLink: 'Pour plus d\'informations ou pour rechercher votre numéro DUNS, visitez notre page de recherche DUNS.',
      titles: {
        benefits: 'Avantages d\'avoir un numéro DUNS en France',
        usage: 'Comment utiliser votre numéro DUNS en France',
        getting: 'Obtenir un numéro DUNS en France',
      },
    },
    de: {
      name: 'Germany',
      content: 'In Deutschland werden DUNS-Nummern häufig für staatliche Aufträge verwendet und sind erforderlich für Unternehmen, die Bundesverträge oder Zuschüsse anstreben.',
      specificInfo: 'Deutsche Unternehmen können eine DUNS-Nummer über Dun & Bradstreet Deutschland erhalten.',
      benefits: [
        'Erhöhen Sie die Glaubwürdigkeit Ihres Unternehmens auf dem deutschen Markt',
        'Zugang zu staatlichen Aufträgen und Ausschreibungen',
        'Einfachere Überprüfung durch potenzielle Partner und Kunden',
        'Verbesserte Chancen auf Geschäftskredite',
        'Erforderlich für Entwickler von Google Play Android- und iOS-Apps',
      ],
      usage: [
        'Anmeldung für staatliche Aufträge',
        'Aufbau von Geschäftskredit',
        'Überprüfung der Unternehmensidentität bei potenziellen Partnern',
        'Beantragung von Darlehen oder Kreditlinien',
        'Registrierung von Apps bei Google Play und dem Apple App Store',
      ],
      lookupLink: 'Für weitere Informationen oder um Ihre DUNS-Nummer zu suchen, besuchen Sie unsere DUNS-Suchseite.',
      titles: {
        benefits: 'Vorteile eines DUNS-Nummer in Deutschland',
        usage: 'So verwenden Sie Ihre DUNS-Nummer in Deutschland',
        getting: 'Einen DUNS-Nummer in Deutschland erhalten',
      },
    },
    jp: {
      name: 'Japan',
      content: '日本では、DUNS番号は政府の契約に広く使用されており、企業が国際的に展開するために不可欠です。',
      specificInfo: '日本の企業は、ダン＆ブラッドストリートジャパンを通じてDUNS番号を取得できます。',
      benefits: [
        '日本市場でのビジネスの信頼性を高める',
        '政府契約や入札にアクセスする',
        '潜在的なパートナーや顧客による容易な確認',
        'ビジネスローンや信用を得る可能性の向上',
        'Google Play AndroidおよびiOSアプリ開発者に必要',
      ],
      usage: [
        '政府契約に登録する',
        'ビジネスクレジットを確立する',
        '潜在的なパートナーに対するビジネスの身元を確認する',
        '融資や信用枠を申請する',
        'Google PlayおよびApple App Storeにアプリを登録する',
      ],
      lookupLink: '詳細情報やDUNS番号の検索は、DUNS検索ページをご覧ください。',
      titles: {
        benefits: '日本におけるDUNS番号の利点',
        usage: '日本でのDUNS番号の使い方',
        getting: '日本でDUNS番号を取得する',
      },
    },
    au: {
      name: 'Australia',
      content: 'In Australia, DUNS Numbers are essential for businesses seeking government contracts and for establishing credibility in the Australian market.',
      specificInfo: 'Australian businesses can obtain a DUNS Number through Dun & Bradstreet Australia.',
      benefits: [
        'Enhance your business credibility in the Australian market',
        'Access to government contracts and tenders',
        'Simplified verification process for potential partners and clients',
        'Improved chances of securing business loans and credit',
        'Required for Google Play Android and iOS app developers',
      ],
      usage: [
        'Register for government contracts',
        'Establish business credit',
        'Verify your business identity to potential partners',
        'Apply for loans or lines of credit',
        'Register apps on Google Play and the Apple App Store',
      ],
      lookupLink: 'For more information or to look up your DUNS Number, visit our DUNS Lookup page.',
      titles: {
        benefits: 'Benefits of Having a DUNS Number in Australia',
        usage: 'How to Use Your DUNS Number in Australia',
        getting: 'Getting a DUNS Number in Australia',
      },
    },
    gb: {
      name: 'United Kingdom',
      content: 'In the United Kingdom, DUNS Numbers are widely used for government contracts and are essential for businesses looking to expand internationally.',
      specificInfo: 'UK businesses can obtain a DUNS Number through Dun & Bradstreet UK.',
      benefits: [
        'Enhance your business credibility in the UK market',
        'Access to government contracts and tenders',
        'Simplified verification process for potential partners and clients',
        'Improved chances of securing business loans and credit',
        'Required for Google Play Android and iOS app developers',
      ],
      usage: [
        'Register for government contracts',
        'Establish business credit',
        'Verify your business identity to potential partners',
        'Apply for loans or lines of credit',
        'Register apps on Google Play and the Apple App Store',
      ],
      lookupLink: 'For more information or to look up your DUNS Number, visit our DUNS Lookup page.',
      titles: {
        benefits: 'Benefits of Having a DUNS Number in the United Kingdom',
        usage: 'How to Use Your DUNS Number in the United Kingdom',
        getting: 'Getting a DUNS Number in the United Kingdom',
      },
    },
    ca: {
      name: 'Canada',
      content: 'In Canada, DUNS Numbers are essential for businesses seeking government contracts and for establishing credibility in the Canadian market.',
      specificInfo: 'Canadian businesses can obtain a DUNS Number through Dun & Bradstreet Canada.',
      benefits: [
        'Enhance your business credibility in the Canadian market',
        'Access to government contracts and tenders',
        'Simplified verification process for potential partners and clients',
        'Improved chances of securing business loans and credit',
        'Required for Google Play Android and iOS app developers',
      ],
      usage: [
        'Register for government contracts',
        'Establish business credit',
        'Verify your business identity to potential partners',
        'Apply for loans or lines of credit',
        'Register apps on Google Play and the Apple App Store',
      ],
      lookupLink: 'For more information or to look up your DUNS Number, visit our DUNS Lookup page.',
      titles: {
        benefits: 'Benefits of Having a DUNS Number in Canada',
        usage: 'How to Use Your DUNS Number in Canada',
        getting: 'Getting a DUNS Number in Canada',
      },
    },
    cn: {
      name: 'China',
      content: '在中国，DUNS号码广泛用于政府合同，对于寻求联邦合同或补助金的企业来说是必需的。',
      specificInfo: '中国企业可以通过邓白氏中国获取DUNS号码。',
      benefits: [
        '提升您在中国市场的商业信誉',
        '获取政府合同和招标',
        '简化潜在合作伙伴和客户的验证过程',
        '提高获得商业贷款和信用的机会',
        'Google Play Android和iOS应用开发者必需',
      ],
      usage: [
        '注册政府合同',
        '建立商业信用',
        '向潜在合作伙伴验证您的商业身份',
        '申请贷款或信用额度',
        '在Google Play和Apple App Store上注册应用',
      ],
      lookupLink: '欲了解更多信息或查询您的DUNS号码，请访问我们的DUNS查询页面。',
      titles: {
        benefits: '在中国拥有DUNS号码的好处',
        usage: '如何在中国使用您的DUNS号码',
        getting: '在中国获取DUNS号码',
      },
    },
    in: {
      name: 'India',
      content: 'In India, DUNS Numbers are essential for businesses seeking government contracts and for establishing credibility in the Indian market.',
      specificInfo: 'Indian businesses can obtain a DUNS Number through Dun & Bradstreet India.',
      benefits: [
        'Enhance your business credibility in the Indian market',
        'Access to government contracts and tenders',
        'Simplified verification process for potential partners and clients',
        'Improved chances of securing business loans and credit',
        'Required for Google Play Android and iOS app developers',
      ],
      usage: [
        'Register for government contracts',
        'Establish business credit',
        'Verify your business identity to potential partners',
        'Apply for loans or lines of credit',
        'Register apps on Google Play and the Apple App Store',
      ],
      lookupLink: 'For more information or to look up your DUNS Number, visit our DUNS Lookup page.',
      titles: {
        benefits: 'Benefits of Having a DUNS Number in India',
        usage: 'How to Use Your DUNS Number in India',
        getting: 'Getting a DUNS Number in India',
      },
    },
    br: {
      name: 'Brazil',
      content: 'No Brasil, os números DUNS são amplamente utilizados para contratos governamentais e são essenciais para empresas que buscam expandir seus negócios no mercado brasileiro.',
      specificInfo: 'Empresas brasileiras podem obter um número DUNS através da Dun & Bradstreet Brasil.',
      benefits: [
        'Melhore a credibilidade do seu negócio no mercado brasileiro',
        'Acesso a contratos e licitações governamentais',
        'Processo de verificação simplificado para parceiros e clientes potenciais',
        'Aumenta as chances de garantir empréstimos comerciais e crédito',
        'Necessário para desenvolvedores de aplicativos Google Play Android e iOS',
      ],
      usage: [
        'Registrar-se para contratos governamentais',
        'Estabelecer crédito comercial',
        'Verificar a identidade do seu negócio para parceiros potenciais',
        'Solicitar empréstimos ou linhas de crédito',
        'Registrar aplicativos no Google Play e na Apple App Store',
      ],
      lookupLink: 'Para mais informações ou para consultar seu número DUNS, visite nossa página de Pesquisa DUNS.',
      titles: {
        benefits: 'Benefícios de Ter um Número DUNS no Brasil',
        usage: 'Como Usar Seu Número DUNS no Brasil',
        getting: 'Obtendo um Número DUNS no Brasil',
      },
    },
    mx: {
      name: 'Mexico',
      content: 'En México, los números DUNS son ampliamente utilizados para contratos gubernamentales y son esenciales para las empresas que buscan expandirse en el mercado mexicano.',
      specificInfo: 'Las empresas mexicanas pueden obtener un número DUNS a través de Dun & Bradstreet México.',
      benefits: [
        'Mejore la credibilidad de su negocio en el mercado mexicano',
        'Acceso a contratos y licitaciones gubernamentales',
        'Proceso de verificación simplificado para socios y clientes potenciales',
        'Mejores oportunidades para asegurar préstamos comerciales y crédito',
        'Requerido para desarrolladores de aplicaciones Google Play Android y iOS',
      ],
      usage: [
        'Registrarse para contratos gubernamentales',
        'Establecer crédito comercial',
        'Verificar la identidad de su negocio para socios potenciales',
        'Solicitar préstamos o líneas de crédito',
        'Registrar aplicaciones en Google Play y Apple App Store',
      ],
      lookupLink: 'Para más información o para consultar su número DUNS, visite nuestra página de Búsqueda DUNS.',
      titles: {
        benefits: 'Beneficios de Tener un Número DUNS en México',
        usage: 'Cómo Usar Su Número DUNS en México',
        getting: 'Obteniendo un Número DUNS en México',
      },
    },
    za: {
      name: 'South Africa',
      content: 'In South Africa, DUNS Numbers are essential for businesses seeking government contracts and for establishing credibility in the South African market.',
      specificInfo: 'South African businesses can obtain a DUNS Number through Dun & Bradstreet South Africa.',
      benefits: [
        'Enhance your business credibility in the South African market',
        'Access to government contracts and tenders',
        'Simplified verification process for potential partners and clients',
        'Improved chances of securing business loans and credit',
        'Required for Google Play Android and iOS app developers',
      ],
      usage: [
        'Register for government contracts',
        'Establish business credit',
        'Verify your business identity to potential partners',
        'Apply for loans or lines of credit',
        'Register apps on Google Play and the Apple App Store',
      ],
      lookupLink: 'For more information or to look up your DUNS Number, visit our DUNS Lookup page.',
      titles: {
        benefits: 'Benefits of Having a DUNS Number in South Africa',
        usage: 'How to Use Your DUNS Number in South Africa',
        getting: 'Getting a DUNS Number in South Africa',
      },
    },
    sg: {
      name: 'Singapore',
      content: 'In Singapore, DUNS Numbers are essential for businesses seeking government contracts and for establishing credibility in the Singaporean market.',
      specificInfo: 'Singaporean businesses can obtain a DUNS Number through Dun & Bradstreet Singapore.',
      benefits: [
        'Enhance your business credibility in the Singaporean market',
        'Access to government contracts and tenders',
        'Simplified verification process for potential partners and clients',
        'Improved chances of securing business loans and credit',
        'Required for Google Play Android and iOS app developers',
      ],
      usage: [
        'Register for government contracts',
        'Establish business credit',
        'Verify your business identity to potential partners',
        'Apply for loans or lines of credit',
        'Register apps on Google Play and the Apple App Store',
      ],
      lookupLink: 'For more information or to look up your DUNS Number, visit our DUNS Lookup page.',
      titles: {
        benefits: 'Benefits of Having a DUNS Number in Singapore',
        usage: 'How to Use Your DUNS Number in Singapore',
        getting: 'Getting a DUNS Number in Singapore',
      },
    },
    ae: {
      name: 'United Arab Emirates',
      content: 'In the United Arab Emirates, DUNS Numbers are essential for businesses seeking government contracts and for establishing credibility in the UAE market.',
      specificInfo: 'UAE businesses can obtain a DUNS Number through Dun & Bradstreet UAE.',
      benefits: [
        'Enhance your business credibility in the UAE market',
        'Access to government contracts and tenders',
        'Simplified verification process for potential partners and clients',
        'Improved chances of securing business loans and credit',
        'Required for Google Play Android and iOS app developers',
      ],
      usage: [
        'Register for government contracts',
        'Establish business credit',
        'Verify your business identity to potential partners',
        'Apply for loans or lines of credit',
        'Register apps on Google Play and the Apple App Store',
      ],
      lookupLink: 'For more information or to look up your DUNS Number, visit our DUNS Lookup page.',
      titles: {
        benefits: 'Benefits of Having a DUNS Number in the United Arab Emirates',
        usage: 'How to Use Your DUNS Number in the United Arab Emirates',
        getting: 'Getting a DUNS Number in the United Arab Emirates',
      },
    },
    nl: {
      name: 'Netherlands',
      content: 'In Nederland worden DUNS-nummers veel gebruikt voor overheidscontracten en zijn essentieel voor bedrijven die hun activiteiten internationaal willen uitbreiden.',
      specificInfo: 'Nederlandse bedrijven kunnen een DUNS-nummer verkrijgen via Dun & Bradstreet Nederland.',
      benefits: [
        'Verhoog de geloofwaardigheid van uw bedrijf op de Nederlandse markt',
        'Toegang tot overheidscontracten en aanbestedingen',
        'Vereenvoudigd verificatieproces voor potentiële partners en klanten',
        'Verbeterde kansen op het verkrijgen van bedrijfsleningen en krediet',
        'Vereist voor ontwikkelaars van Google Play Android- en iOS-apps',
      ],
      usage: [
        'Registreren voor overheidscontracten',
        'Zakelijke krediet opbouwen',
        'Verifieer uw bedrijfsidentiteit bij potentiële partners',
        'Aanvragen van leningen of kredietlijnen',
        'Apps registreren op Google Play en de Apple App Store',
      ],
      lookupLink: 'Voor meer informatie of om uw DUNS-nummer op te zoeken, bezoekt u onze DUNS Zoekpagina.',
      titles: {
        benefits: 'Voordelen van het Hebben van een DUNS-Nummer in Nederland',
        usage: 'Hoe U Uw DUNS-Nummer in Nederland Gebruikt',
        getting: 'Een DUNS-Nummer in Nederland Verkrijgen',
      },
    },
    it: {
      name: 'Italy',
      content: 'In Italia, i numeri DUNS sono ampiamente utilizzati per i contratti governativi e sono essenziali per le aziende che desiderano espandersi nel mercato italiano.',
      specificInfo: 'Le aziende italiane possono ottenere un numero DUNS tramite Dun & Bradstreet Italia.',
      benefits: [
        'Aumenta la credibilità della tua azienda nel mercato italiano',
        'Accesso a contratti e gare d\'appalto governativi',
        'Processo di verifica semplificato per potenziali partner e clienti',
        'Migliori possibilità di ottenere prestiti commerciali e crediti',
        'Richiesto per gli sviluppatori di app Google Play Android e iOS',
      ],
      usage: [
        'Registrarsi per contratti governativi',
        'Stabilire credito commerciale',
        'Verificare l\'identità della tua azienda per potenziali partner',
        'Richiedere prestiti o linee di credito',
        'Registrare app su Google Play e Apple App Store',
      ],
      lookupLink: 'Per ulteriori informazioni o per cercare il tuo numero DUNS, visita la nostra pagina di Ricerca DUNS.',
      titles: {
        benefits: 'Vantaggi di Avere un Numero DUNS in Italia',
        usage: 'Come Utilizzare il Tuo Numero DUNS in Italia',
        getting: 'Ottenere un Numero DUNS in Italia',
      },
    },
    es: {
      name: 'Spain',
      content: 'En España, los números DUNS son ampliamente utilizados para contratos gubernamentales y son esenciales para las empresas que buscan expandirse en el mercado español.',
      specificInfo: 'Las empresas españolas pueden obtener un número DUNS a través de Dun & Bradstreet España.',
      benefits: [
        'Mejore la credibilidad de su negocio en el mercado español',
        'Acceso a contratos y licitaciones gubernamentales',
        'Proceso de verificación simplificado para socios y clientes potenciales',
        'Mejores oportunidades para asegurar préstamos comerciales y crédito',
        'Requerido para desarrolladores de aplicaciones Google Play Android y iOS',
      ],
      usage: [
        'Registrarse para contratos gubernamentales',
        'Establecer crédito comercial',
        'Verificar la identidad de su negocio para socios potenciales',
        'Solicitar préstamos o líneas de crédito',
        'Registrar aplicaciones en Google Play y Apple App Store',
      ],
      lookupLink: 'Para más información o para consultar su número DUNS, visite nuestra página de Búsqueda DUNS.',
      titles: {
        benefits: 'Beneficios de Tener un Número DUNS en España',
        usage: 'Cómo Usar Su Número DUNS en España',
        getting: 'Obteniendo un Número DUNS en España',
      },
    },
    kr: {
      name: 'South Korea',
      content: '대한민국에서는 DUNS 번호가 정부 계약에 널리 사용되며, 한국 시장에서의 신뢰성을 구축하는 데 필수적입니다.',
      specificInfo: '한국 기업은 Dun & Bradstreet Korea를 통해 DUNS 번호를 획득할 수 있습니다.',
      benefits: [
        '한국 시장에서의 비즈니스 신뢰성 향상',
        '정부 계약 및 입찰에 접근',
        '잠재적인 파트너 및 고객을 위한 간편한 검증 과정',
        '비즈니스 대출 및 신용 확보 가능성 향상',
        'Google Play Android 및 iOS 앱 개발자 필수',
      ],
      usage: [
        '정부 계약에 등록',
        '비즈니스 신용 확립',
        '잠재적인 파트너에게 비즈니스 신원을 확인',
        '대출 또는 신용 한도 신청',
        'Google Play 및 Apple App Store에 앱 등록',
      ],
      lookupLink: '자세한 정보나 DUNS 번호를 조회하려면, 저희 DUNS 조회 페이지를 방문하세요.',
      titles: {
        benefits: '대한민국에서 DUNS 번호를 보유하는 이점',
        usage: '대한민국에서 DUNS 번호를 사용하는 방법',
        getting: '대한민국에서 DUNS 번호 획득',
      },
    },
  };
  
  export default countryData;
