import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import countryData from '../data/countryData';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const PageTitle = styled.h1`
  color: #3498db;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 2rem;
  text-align: center;
`;

const CountryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
`;

const CountryLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  color: #2c3e50;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3498db;
    color: #ffffff;
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const CountryList = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>DUNS Number Lookup by Country | Global Business Identifier</title>
        <meta name="description" content="Find DUNS Number information for businesses in various countries. Access country-specific DUNS lookup services and learn about international business identification." />
        <meta name="og:title" content="DUNS Number Lookup by Country | Global Business Identifier" />
        <meta name="twitter:title" content="DUNS Number Lookup by Country | Global Business Identifier" />
        <meta name="og:description" content="Find DUNS Number information for businesses in various countries. Access country-specific DUNS lookup services and learn about international business identification." />
        <meta name="twitter:description" content="Find DUNS Number information for businesses in various countries. Access country-specific DUNS lookup services and learn about international business identification." />
      </Helmet>
      <PageTitle>DUNS Number Lookup by Country</PageTitle>
      <Paragraph>Select a country to learn about DUNS Numbers and how to obtain them in specific regions:</Paragraph>
      <CountryGrid>
        {Object.entries(countryData).map(([countryCode, country]) => (
          <CountryLink key={countryCode} to={`/country-info/${countryCode}`}>
            {country.name}
          </CountryLink>
        ))}
      </CountryGrid>
    </PageContainer>
  );
};

export default CountryList;