import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled, { keyframes } from 'styled-components';
import countryData from '../data/countryData';
import AdComponent from '../components/AdComponent';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideInFromLeft = keyframes`
  from { transform: translateX(-50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, #3498db, #8e44ad);
  color: white;
  padding: 4rem 0;
  text-align: center;
  animation: ${fadeIn} 1s ease-out;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #fff;
`;

const HeroSubtitle = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #fff;
  font-weight: normal;
`;

const CTAButton = styled(Link)`
  display: inline-block;
  background-color: #e74c3c;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    background-color: #c0392b;
    transform: translateY(-3px);
    color: white;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
`;

const InfoSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin: 4rem 0;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const InfoCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 2rem;
  flex: 1;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  animation: ${slideInFromLeft} 0.5s ease-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  }
`;

const InfoTitle = styled.h2`
  color: #3498db;
  margin-bottom: 1rem;
`;

const InfoList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const InfoListItem = styled.li`
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 1.5rem;

  &:before {
    content: '✓';
    color: #3498db;
    position: absolute;
    left: 0;
  }
`;

const SectionTitle = styled.h2`
  color: #2c3e50;
  font-size: 2rem;
  text-align: center;
  margin: 3rem 0 2rem;
`;

const HowItWorksList = styled.ol`
  counter-reset: step-counter;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const HowItWorksItem = styled.li`
  counter-increment: step-counter;
  width: 200px;
  text-align: center;
  position: relative;
  padding-top: 80px;

  &:before {
    content: counter(step-counter);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    background-color: #3498db;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

const FAQSection = styled.section`
  margin: 4rem 0;
`;

const FAQItem = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e9ecef;
  }
`;

const FAQQuestion = styled.h3`
  color: #3498db;
  margin-bottom: 0.5rem;
`;

const CountryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
`;

const CountryLink = styled(Link)`
  display: block;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  color: #2c3e50;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3498db;
    color: white;
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
`;

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Global DUNS Number Finder | Fast & Easy Business Identifier Lookup</title>
        <meta name="og:title" content="Global DUNS Number Finder | Fast & Easy Business Identifier Lookup" />
        <meta name="twitter:title" content="Global DUNS Number Finder | Fast & Easy Business Identifier Lookup" />
        <meta name="description" content="Find your DUNS number easily with our global DUNS number lookup service. Fast, accurate, and comprehensive business identifier search." />
        <meta name="og:description" content="Find your DUNS number easily - Global DUNS number lookup service" />
        <meta name="twitter:description" content="Find your DUNS number easily - Global DUNS number lookup service" />
      </Helmet>
      <HeroSection>
        <PageContainer>
          <HeroTitle>Unlock Your Business Potential with a DUNS Number</HeroTitle>
          <HeroSubtitle>Discover the power of global business identification and credibility with your unique DUNS (Data Universal Numbering System) number.</HeroSubtitle>
          <CTAButton to="/duns-lookup">Lookup Your DUNS Number</CTAButton>
        </PageContainer>
      </HeroSection>

      <PageContainer>
        
        <InfoSection>
          <InfoCard>
            <InfoTitle>What is a DUNS Number?</InfoTitle>
            <p>A DUNS Number is a unique nine-digit identifier issued by Dun & Bradstreet. It's the most widely recognized business identification number, used globally for validating and linking company information.</p>
          </InfoCard>
          
          <InfoCard>
            <InfoTitle>Why You Need a DUNS Number</InfoTitle>
            <InfoList>
              <InfoListItem>Essential for government contracts worldwide</InfoListItem>
              <InfoListItem>Required for Apple and Google developer accounts</InfoListItem>
              <InfoListItem>Facilitates global business transactions</InfoListItem>
              <InfoListItem>Enhances credibility and enables credit checks</InfoListItem>
            </InfoList>
          </InfoCard>
          
          <InfoCard>
            <InfoTitle>Benefits of a DUNS Number</InfoTitle>
            <InfoList>
              <InfoListItem>Increased visibility to potential partners</InfoListItem>
              <InfoListItem>Improved credibility in the global marketplace</InfoListItem>
              <InfoListItem>Access to business credit opportunities</InfoListItem>
              <InfoListItem>Simplified registration for government contracts</InfoListItem>
            </InfoList>
          </InfoCard>
        </InfoSection>
        
        <AdComponent position="middle" adId="9741859389" />
        
        <SectionTitle>How Our DUNS Lookup Service Works</SectionTitle>
        <HowItWorksList>
          <HowItWorksItem>Enter your business information in our secure lookup form</HowItWorksItem>
          <HowItWorksItem>Our system searches the global Dun & Bradstreet database</HowItWorksItem>
          <HowItWorksItem>Receive instant results with your DUNS number and business details</HowItWorksItem>
          <HowItWorksItem>Use the information to verify your business identity or apply for contracts</HowItWorksItem>
        </HowItWorksList>

        <FAQSection>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
          <FAQItem>
            <FAQQuestion>Is a DUNS number free?</FAQQuestion>
            <p>Yes, obtaining a DUNS number is free for all businesses.</p>
          </FAQItem>
          <FAQItem>
            <FAQQuestion>How long does it take to get a DUNS number?</FAQQuestion>
            <p>It typically takes 1-2 business days to receive your DUNS number.</p>
          </FAQItem>
          <FAQItem>
            <FAQQuestion>Can I have multiple DUNS numbers?</FAQQuestion>
            <p>Each physical location of your business can have its own DUNS number.</p>
          </FAQItem>
        </FAQSection>
        
        <SectionTitle>Lookup DUNS Number by Country</SectionTitle>
        <CountryGrid>
          {Object.entries(countryData).map(([countryCode, country]) => (
            <CountryLink key={countryCode} to={`/country-info/${countryCode}`} title={`Find Your DUNS Number In ${country.name}`}>
              {country.name}
            </CountryLink>
          ))}
        </CountryGrid>
        
      </PageContainer>
    </div>
  );
};

export default Home;